.paginator {
  min-width: 310px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fa9bc;

  .MuiSvgIcon-root {
    color: #9fa9bc;
  }

  .MuiButton-label {
    color: #9fa9bc;
    /*font-size: 16px !important;*/
  }
}
