.circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: solid #c79bde 2px;
  font-size: 15px;
  color: #c79bde;
  line-height: 32px;
  text-align: center;
  background: transparent;
  margin-right: 0.75rem;
  margin-left: 0.5rem;
}

.popover-chat-list {
  min-width: 500px;
  max-height: 650px;
  overflow: hidden;

  &-content {
    height: 100%;
    padding: 0;
  }
}

.popover-chat-list::-webkit-scrollbar {
  display: none;
}
