.ClientChat {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #00000029;
  border-radius: 10px;
  opacity: 1;

  .headerClientChat {
    background-color: #8bdbd9;
    color: white;
    padding: 10px 20px 10px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.clientList .headerInfoUser {
  height: 42px !important;
  background-color: #573c66 !important;
  border-radius: 0 !important;
}

.clientList {
  flex: 1;

  .headerInfoUser {
    display: flex;
    justify-content: space-between;
    height: 80px;
    background-color: #24122d;
    border-radius: 10px 0 0 0;
    padding: 0 20px 0 20px;
    align-items: center;
    border-right: 1px solid white;

    div {
      color: white;
      font-size: 16px;
    }

    svg {
      cursor: pointer;
      font-size: 30px;
    }
  }

  .searchChat {
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid #e2e2e2;

    svg {
      color: #9fa9bc;
    }
  }

  .listProcess {
    height: 610px;
    overflow: auto;
    background-color: white;

    .process {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      // height: 70px;
      background-color: white;
      border-bottom: 1px solid #e2e2e2;
      cursor: pointer;

      div {
        align-self: center;
      }

      .containerProcess {
        width: 100%;

        .codProc {
          color: #707070;
          font-weight: bold;
          font-size: 16px;
        }

        .preview {
          color: #c8c8c8;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 345px;
        }
      }

      .hour {
        color: #707070;
        font-size: 12px;
      }

      .qtdeMessages {
        background-color: #6f2c91;
        padding: 4px;
        border-radius: 15px;
        min-width: 27px;
        text-align: center;
        color: white;
      }
    }

    .process:hover {
      background-color: #e8e8e8;
    }
  }

  .listProcess::-webkit-scrollbar {
    width: 7px;
    margin-left: -10px;
  }

  .listProcess::-webkit-scrollbar-thumb {
    background: #6f2c91;
    border-radius: 20px;
  }

  .listProcess::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }
}

.emptyRooms {
  font-size: 20px;
  color: #6f2c91;
  padding: 20px;
  text-align: center;
}

.notification-item-content {
  font-size: 0.875rem;

  padding: 8px 8px 16px 38px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-item {
  cursor: pointer;
}
.notification-item:hover {
  background-color: rgba(109, 109, 109, 0.2);
}

.notification-item-text {
  font-size: 0.75rem;

  display: flex;
  align-items: center;

  color: var(--dark-secondary-color);
  font-weight: 300;
}

.notification-item-time {
  font-size: 0.725rem;
  padding-right: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
}
