.card-next-header-title {
  margin-top: 1rem;
  padding: 0;

  color: #5b5b5b;
  font-size: 0.875rem;
  opacity: 0.8;
  font-weight: bold;

  position: relative;
  z-index: 1;
}
.icon-card {
  color: #5b5b5b;
  font-size: 18px;
}

.card-content-next {
  position: relative;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  min-width: 150px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    .favorite {
      opacity: 0.4;
      transform: scale(0.8);
    }
  }

  .favorited {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.9rem;
    padding: 0.3rem 0 0.5rem 0.35rem;
    letter-spacing: 0;
    z-index: 9;
    opacity: 1;
    background: #24122d;
    border-bottom-right-radius: 90%;
  }

  .favorite {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.1);
    width: 1.9rem;
    padding: 0.3rem 0 0.5rem 0.2rem;
    letter-spacing: 0;
    z-index: 9;
    transition: all 0.4s cubic-bezier(0.2, 0.8, 0.2, 1.2) 0s;
    opacity: 0;
    background: #24122d;
    border-bottom-right-radius: 90%;

    &:hover {
      opacity: 1;
      transform: scale(1);
    }

    i {
      transition: all 0.4s cubic-bezier(0.2, 0.8, 0.2, 1.2) 0s;
    }
  }

  .next-pendent {
    background: rgba(252, 67, 40, 0.1);
    color: #fc4328;

    text-align: center;
    padding: 0.2rem;
    border-radius: 4px;

    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      font-size: 12px !important;
      max-width: 25ch !important;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .next-payment {
    background: rgb(229, 247, 247);
    color: rgb(0, 178, 175);
    text-align: center;
    padding: 0.2rem;
    border-radius: 4px;

    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      font-size: 12px !important;
      max-width: 25ch !important;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .card-text {
    font-size: 0.625rem;
    margin-top: 0.725rem;
    text-transform: uppercase;
    color: #010101;
    font-weight: 700;
  }

  .card-info-title {
    color: #3f3f3f;
    font-weight: 700;
    font-size: 0.625rem;

    margin-top: 4px;
    display: flex;
    flex-direction: column;

    span:last-child {
      color: #5b5b5b;
      font-weight: 400;
    }
  }

  .card-info-vessel {
    font-size: 0.625rem;
    color: #5b5b5b;

    span {
      color: #010101;
    }
  }

  .card-info-text-details {
    color: #5b5b5b;
    font-size: 0.625rem;
    font-weight: 400;
  }

  .card-info-icons {
    color: #5b5b5b;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .card-info-date {
    margin-top: 0.2rem;
    font-size: 0.625rem;

    span:first-child {
      font-weight: 700;
    }

    span:last-child {
      margin-left: 2px;
      font-weight: 700;
      color: #047537;
    }

    .col {
      background: #f1f1f1;
      color: #5b5b5b;
      font-size: 0.675rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.4rem;
      border-radius: 4px;
    }
  }
}
