.navigation-controls {
  position: absolute;
  bottom: 8px;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.navigation-button {
  cursor: pointer;
}
