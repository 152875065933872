.dropdown {
  z-index: 1000000;
}
.dropdown-toggle {
  border-radius: 3px;
  color: #5b5b5b;
  background: #fff;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.dropdown-toggle::after {
  border: 0 !important;
}

.dropdown-item:hover {
  background-color: #c79bde;
  color: #6f2c91;
}
