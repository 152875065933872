.kanbanContainer {
  position: relative;
}

.card-scroll {
  height: calc(100vh - 240px);
  overflow-y: scroll;
}

.card-scroll::-webkit-scrollbar {
  width: 6px;
  color: #6f2c91;
}

.card-scroll::-webkit-scrollbar-thumb {
  background: #24122d;
  border-radius: 8px;
}

.card-scroll::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 8px;
}

.loading {
  color: #c79bde !important;
  z-index: 9999999999 !important;
}

.menu-ordination {
  z-index: 9999999999 !important;
}

.empty-text {
  display: flex;
  justify-content: center;
  font-size: large;
  color: #6f2c91;
  font-weight: 700;
  padding-top: 2rem;
}
.kanban-paper {
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  margin-bottom: 8px;
}
