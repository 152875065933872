.broker-container {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  align-items: baseline;
}

.broker-div {
  display: grid;
  grid-template-columns: 78% 15%;
  width: 80%;
  gap: 6px;
  align-items: baseline;

  .broker-submit {
    text-align: left;
    background-color: #6f2c91;
    color: white;
    height: 56px;
    padding: 0px 12px 0px 12px;
    width: 139px;
    font-weight: bold;
    text-transform: none;

    span {
      font-size: 14px;
      text-transform: none !important;
    }
  }
}
