.card-chat {
  height: 100%;
  min-height: 616px;
  background-color: #00b2af;
}
.card-chat-content {
  height: 100% !important;

  .card-messages {
    min-height: 400px;
    max-height: 616px;
    // background-color: #767676;
    overflow: auto;

    .emptyMessage {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1rem;

      margin-top: 32px;
      color: #c79bde;
    }

    .loadingMessages {
      color: #c79bde !important;
      z-index: 9999999999 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .card-messages::-webkit-scrollbar {
    width: 7px;
    margin-left: -10px;
  }

  .card-messages::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  .card-messages::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  .title-container {
    padding-bottom: 2%;

    .title-button {
      background-color: #ffff;
      border: 1px solid #cfcfcf;
      color: #767676;
      padding: 10px 25px;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 12px 12px 0px 0px;
    }

    .title-button-actived {
      background-color: #f2dffc;
      border: none;
      color: #6f2c91;
      padding: 10px 25px;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 12px 12px 0px 0px;
    }

    .title-button-actived:focus {
      outline: none !important;
      border-radius: 12px 12px 0px 0px;
    }
  }

  .card-chat {
    background: #e8e8e8;
    border-radius: 2%;
    overflow: hidden;
    height: 100%;

    // overflow-y: auto;

    .card-header {
      width: 100%;
      background: #0303031a;
      position: relative;
      border: none;
      text-align: center;
      font-size: 12px;
      a {
        color: #00b2af;
      }
      p {
        margin: 0;
      }
    }

    .card-container {
      padding: 3%;
      margin-bottom: 10px;
      overflow: auto;
      background-color: #e8e8e8;
      position: relative;
      height: 90%;

      .card-chat-received-messages {
        img {
          width: 45px;
          height: 45px;
          margin-right: 3%;
        }

        max-width: 86%;
        min-width: 20%;
        border-radius: 0 15px 15px 15px;
        overflow: hidden;
        float: left;
        text-align: justify;
        color: #646464;
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        padding: 2%;
        background: #ffffff none repeat scroll 0 0;

        p {
          margin-bottom: 0px;
        }

        .time_date {
          color: #646464;
          display: block;
          font-size: 10px;
          margin: 0;
          float: right;
        }
      }

      .card-chat-sent-messages {
        max-width: 86%;
        min-width: 20%;
        border-radius: 15px 15px 0px 15px;
        overflow: hidden;
        float: right;
        background-color: #757575;
        /* background-color: #6f2c91; */
        text-align: justify;
        color: #fff;
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        padding: 2%;

        p {
          margin-bottom: 0px;
        }

        .time_date {
          color: #ffffff;
          display: flex;
          font-size: 10px;
          margin: 0;
          float: right;
        }
      }
    }
  }
}
