.note-item {
  .note-divider {
    text-align: center;
    width: 70%;
  }

  .note-add {
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
      0 0 0 1px rgba(9, 30, 66, 0.08);
    margin-bottom: 16px;
    position: relative;
  }

  .note-button {
    /*position: absolute;
			bottom: 2px;
			left: 2px;*/
    background-color: #f2dffc;
    color: #6f2c91;
    display: block;
  }

  .note-priority-select {
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 32px;
  }

  .dot {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 6px;
    margin-top: 8px;
    background-color: #bbb;
    opacity: 0.6;
    cursor: pointer;
  }

  .high {
    background-color: red;
  }

  .normal {
    background-color: orange;
  }

  .low {
    background-color: green;
  }
}
