.dvContainer {
  background: #24122d;
  height: calc(100vh - 60px);

  .MuiMenuItem-root {
    font-size: 0.85rem !important;
    font-weight: 500;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
  .MuiDrawer-paper {
    display: inherit;
    position: initial;
    background: #24122d;
    color: #fff !important;
    font-weight: 700;
    overflow: hidden;
  }

  .MuiListItem-gutters {
    padding: 0.2rem 0;
  }
}

.dvMenu {
  color: #fff;
  padding: 8px 0px 8px 4px !important;
  // display: flex;
  // align-items: center;

  list-style: none;
  padding: 10px;
  display: inline-block;
  background: #777;
  line-height: 1em;
  margin: 0 5px;
  cursor: pointer;
  color: #fff;
  position: relative;
  z-index: 1;
}

.dvMenu::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 178, 175, 1) !important;
  z-index: -1;
  transition: width 0.5s ease-in-out;
}

.dvMenu:hover::after {
  width: 100%;
}

.dvIconTitle {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
