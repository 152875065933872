.issue-buttons {
  border-color: #6f2c91 !important;
}

.issue-buttons:disabled {
  background-color: white;
  color: #cacaca !important;
  border-color: #c6c6c6 !important;
}

.issue-buttons:hover {
  background-color: #8544a5 !important;
  color: white !important;
}
