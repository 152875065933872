.messenger {
  max-height: 860px !important;
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 16px;
    background-color: #24122d;
    color: #fff;
    height: 80px;
    border-radius: 0 10px 0 0;
  }

  .message-container {
    width: 100%;
    height: 800px;
    position: relative;

    .message-list {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow-y: scroll;
      padding-top: 16px;
      padding-bottom: 86px;
      bottom: 0;
      left: 0;
      background-color: #e8e8e8;
    }

    .emptyMessageChat {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      svg {
        color: #6f2c91;
        font-size: 200px;
        align-self: center;
        width: 100%;
      }

      p {
        font-size: 20px;
        color: #6f2c91;
        text-align: center;
      }
    }

    .message {
      display: inline-block;
      width: 100%;

      .card-chat-sent-messages {
        max-width: 86%;
        min-width: 20%;
        border-radius: 10px 10px 0px 10px;
        overflow: hidden;
        float: right;
        background-color: #6f2c91;
        text-align: justify;
        color: #fff;
        font-size: 14px;
        margin: 0;
        // margin-bottom: 10px;
        margin-right: 15px;
        padding: 8px 12px;

        p {
          margin-bottom: 5px;
        }

        .time_date {
          float: right;
          margin: -10px 0 -5px 4px;
        }
      }

      .card-chat-received-messages {
        max-width: 86%;
        min-width: 20%;
        border-radius: 0 15px 15px 15px;
        overflow: hidden;
        float: left;
        text-align: justify;
        color: #646464;
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        background: #ffffff none repeat scroll 0 0;
        margin-left: 15px;
        padding: 8px 12px;

        p {
          margin-bottom: 0px;
        }

        .time_date {
          color: #646464;
          display: block;
          font-size: 10px;
          margin: 0;
          float: right;
        }
      }
    }

    .message-list::-webkit-scrollbar {
      width: 6px;
      color: #6f2c91;
    }

    .message-list::-webkit-scrollbar-thumb {
      background: #24122d;
      border-radius: 8px;
    }

    .message-list::-webkit-scrollbar-track {
      background: #ddd;
      border-radius: 8px;
    }

    .inputSendMessage {
      position: absolute;
      left: 50%;
      bottom: 0%;
      transform: translateX(-50%);
      background-color: #fff;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .send-message {
      height: 50px;
      background-color: aquamarine;
      position: absolute;
      bottom: 0;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .message-container {
    width: 100%;
    height: 550px;
    position: relative;

    .message-list {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow-y: scroll;
      padding-top: 16px;
      padding-bottom: 76px;
      bottom: 0;
      left: 0;
      background-color: #e8e8e8;
    }

    .emptyMessageChat {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      svg {
        color: #6f2c91;
        font-size: 200px;
        align-self: center;
        width: 100%;
      }

      p {
        font-size: 20px;
        color: #6f2c91;
        text-align: center;
      }
    }

    .message {
      display: inline-block;
      width: 100%;

      .card-chat-sent-messages {
        max-width: 86%;
        min-width: 20%;
        border-radius: 10px 10px 0px 10px;
        overflow: hidden;
        float: right;
        background-color: #6f2c91;
        text-align: justify;
        color: #fff;
        font-size: 14px;
        margin: 0;
        // margin-bottom: 10px;
        margin-right: 15px;
        padding: 8px 12px;

        p {
          margin-bottom: 5px;
        }

        .time_date {
          float: right;
          margin: -10px 0 -5px 4px;
        }
      }

      .card-chat-received-messages {
        max-width: 86%;
        min-width: 20%;
        border-radius: 0 15px 15px 15px;
        overflow: hidden;
        float: left;
        text-align: justify;
        color: #646464;
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        background: #ffffff none repeat scroll 0 0;
        margin-left: 15px;
        padding: 8px 12px;

        p {
          margin-bottom: 0px;
        }

        .time_date {
          color: #646464;
          display: block;
          font-size: 10px;
          margin: 0;
          float: right;
        }
      }
    }

    .messages::-webkit-scrollbar {
      width: 6px;
      color: #6f2c91;
    }

    .messages::-webkit-scrollbar-thumb {
      background: #24122d;
      border-radius: 8px;
    }

    .messages::-webkit-scrollbar-track {
      background: #ddd;
      border-radius: 8px;
    }

    .inputSendMessage {
      position: absolute;
      left: 50%;
      bottom: 0%;
      transform: translateX(-50%);
      background-color: #fff;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    .send-message {
      height: 50px;
      background-color: aquamarine;
      position: absolute;
      bottom: 0;
    }
  }
}
