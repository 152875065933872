.others-cnpjs {
  display: grid;
  grid-template-columns: 48% 22% 22%;
  margin-top: 20px;
  gap: 28px;
  /* height: 54px; */

  .confirm {
    background-color: #6f2c91;
    color: white;
    font-size: 18px;
    font-weight: bold;
    flex: 1;
  }

  .confirm:disabled {
    color: #ccc;
    background-color: #794892;
  }

  .cancel {
    background-color: white;
    color: #6f2c91;
    border-color: #6f2c91;
    font-size: 18px;
    font-weight: bold;
    flex: 1;
  }

  .disabledButton {
    color: rgba(0, 0, 0, 0.26) !important;
    border-top-color: #e2e2e2 !important;
    background-color: white !important;
  }
}

.company-cnpj {
  display: grid;
  grid-template-columns: 53% 40%;
  gap: 24px;
}
