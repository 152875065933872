@import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");

* {
  font-family: Nunito !important;
  box-sizing: border-box;
}

html {
  background: #f3f3f3;
}

body {
  margin: 0;
  padding: 0;
}

.MuiTypography-body1 {
  font-family: Nunito !important;
}

.MuiOutlinedInput-root.Mui-focused {
  fieldset {
    border-color: #6f2c91 !important;
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #6f2c91 !important;
}

.MuiButton-label {
  text-transform: capitalize;
}

.cursorPointer {
  cursor: pointer;
}

#root {
  overflow: hidden;
}

#root::-webkit-scrollbar {
  display: none;
}
