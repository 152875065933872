.external-container {
  padding: 0;
  min-width: 150px;
  position: relative;

  cursor: pointer;

  .external-title {
    margin-top: 1rem;
    margin-left: 0.8rem;

    color: #5b5b5b;
    font-size: 0.875rem;
    opacity: 0.8;
    font-weight: bold;
  }

  .card-title {
    color: #696969;
    font-size: 0.75rem;
  }

  .image-delete {
    position: absolute;
    z-index: 9999999;

    bottom: 8px;
    right: 4px;

    color: #6f2c91;
    opacity: 0.6;
  }

  .card-content {
    background-color: #e9e8e8;
    font-size: 0.75rem;
    font-weight: 500;
    color: #696969;
    border-radius: 8px;

    padding-top: 0.5rem;
    margin-bottom: 8px;

    .card-text {
      font-size: 0.625rem;
      margin-top: 0.725rem;
      text-transform: uppercase;
      color: #5b5b5b;
      font-weight: 700;
    }

    .card-info-data {
      margin-top: 0.2rem;
      font-size: 0.625rem;

      .col {
        background: #f1f1f1;
        color: #5b5b5b;
        font-size: 0.675rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem;
        border-radius: 4px;
      }
    }

    .return-info-ship {
      font-size: 0.525rem;
      font-weight: 700;
      margin-top: 4px;
    }

    .return-info {
      font-size: 0.625rem;
      margin-top: 0.25rem;

      .col:last-child {
        text-transform: uppercase;
      }
    }
  }
}

.external-container::-webkit-scrollbar {
  width: 6px;
  color: #6f2c91;
}

.external-container::-webkit-scrollbar-thumb {
  background: #24122d;
  border-radius: 8px;
}

.external-container::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 8px;
}
