/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

// styles para  o input de mensagens

.card-chat-input {
  width: 95%;
  left: 14px;
  right: 16px;
  bottom: 8px;
  position: absolute;
}

/* Add styles to the form container */
.form-container {
  min-width: 43%;
  padding: 10px;
  /* height: 96%; */
  height: 78vh;
  background: #4caf50; //remover no final
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: gray;
  margin-top: 16px;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

.chatOpen {
  display: block;
}

.chatClose {
  display: none;
}

.header-costumer {
  width: 100%;
  float: right;
}

.containerLabelData {
  display: flex;
  justify-content: center;
}

.labelData {
  background-color: #6f2c91;
  color: white;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 6px 22px 6px 22px;
  width: auto;
  display: table;
}

.containerMessageDefault {
  display: inline-block;
  width: 100%;
}

.userReceivedMessage {
  font-weight: bold;
  color: #00b2af;
  margin-bottom: 7px;
}

.userSentMessage {
  font-weight: bold;
  color: #fff;
  margin-bottom: 7px;
}

.iconDone {
  margin-left: 8px;
  font-size: 16px !important;
}
