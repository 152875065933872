.dividerDocumentTab {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
  background-color: #e2e2e2 !important;
  height: 2px !important;
}

.titleUploadDocumentTab {
  color: #707070;
  font-size: 16px;
  padding-left: 20px;
  padding-bottom: 7px;
}

.msgAlertDocumentTab {
  color: #fc4328;
  font-size: 14px;

  svg {
    margin-right: 7px;
  }
}

.expansionDocument {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 10px !important;
  opacity: 1;
  box-shadow: none !important;
  margin-bottom: 4px;

  .titleExpansionPanelDocumentTab {
    color: #707070;
    font-size: 16px;
    font-weight: bold;
  }

  .iconErrorDocument {
    color: red;
    margin-left: 10px;
  }

  .listDocument {
    width: 100%;

    span {
      div {
        font-size: 16px;
        letter-spacing: 0;
        color: #5b5b5b;
        opacity: 1;
      }
    }
  }

  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:hover {
    background: #f2dffc;
    opacity: 1;
  }

  .containerListItemDocument {
    border-bottom: 1px #2c28284d solid;
  }

  .documentEmpty {
    font-size: 20px;
  }

  .containerFileDocumentTab {
    width: 100%;

    .contentFileDocumentTab {
      border: 1px solid #a4a0a0;
      opacity: 1;
      border-radius: 10px;
    }

    .msgSizeFileDocumentTab {
      color: #fc4328;
      margin-top: 18px;
      margin-left: 15px;
      font-size: 12px;
    }

    .containerUploadDocumentTab {
      flex: 1;
      border-right: 1px solid #a4a0a0;
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 15px;

      .descriptionUploadDocumentTab {
        border: 1px dashed;
        padding: 26px;
        border-radius: 5px;
        margin-top: 10px;
        border-color: #707070;

        p {
          text-align: center;
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: bold;
        }

        div {
          text-align: center;
        }

        img {
          color: #6f2c91;
          font-size: 38px;
          margin-bottom: 15px;
        }
      }

      .btnUploadDocumentTab {
        background-color: #6f2c91;
        color: white;
        border-radius: 9px;
      }
    }

    .containerListDocumentTab {
      flex: 1;
      padding: 20px;

      .containerItemsDocumentTab {
        overflow-y: auto;
        max-height: 220px;
      }

      .itemListUploadDocumentTab {
        display: flex;
        margin-bottom: 20px;
        margin-top: 20px;
        height: 45px;

        .previewItemDocumentTab {
          align-self: center;

          img {
            width: 70px;
            max-height: 45px;
          }
        }

        .headerItemDocumentTab {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          color: #9597a6;
          color: #9597a6;

          svg {
            color: #9fa9bc;
            cursor: pointer;
          }

          .itemNameDocumentTab {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden !important;
          }
        }

        .containerLoadingItemDocumentTab {
          padding-left: 15px;
          padding-right: 15px;

          .loadingItemDocumentTab {
            height: 4px;
            background-color: #00b2af;
          }

          p {
            color: #9597a6;
            margin: 0;
          }
        }
      }
    }

    .titleFileDocumentTab {
      font-size: 20px;
      color: #9597a6;
    }
  }
}

.root {
  width: "100%";
}

.gridContent {
  border-bottom: "1px solid #CFCFCF";
  padding-top: "4%";
  padding-bottom: "4%";
}

.subtitle1 {
  font-size: "1rem";
  font-weight: "500";
  line-height: "1.75";
  letter-spacing: "0.00938em";
  color: "#FC4328";
  width: "100%";
  padding-top: "15px";
  padding-left: "0px";
}

.textLine {
  font-size: "11px";
}

.heading {
  font-size: "19px";
}
