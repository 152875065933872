.alert-return-container {
  position: relative;
  width: 300px;
  background: #fff;
  color: #6f2c91;
  padding: 0;
  margin: 0 auto;
  margin-top: 5rem;
  font-size: 1rem;
  border-radius: 8px;
  height: 250px;

  .alert-return-header {
    font-size: 1.4rem;
    padding: 0.5rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
  }

  .alert-return-body {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 2rem;
    text-align: center;
  }

  .alert-return-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;

    &-ok {
      background-color: #6f2c91 !important;
      color: white !important;
      width: 30%;
      height: 50px;
      padding: 0px 5px 0px 5px;
      font-weight: bold;
      text-transform: none;
      margin-bottom: 8px;
    }
  }
}

.containerIcon {
  border: 2px solid #00b2af;
  position: absolute;
  border-radius: 25px;
  height: 49px;
  left: 50%;
  transform: translate(-50%, 0);
}

.icon {
  font-size: 45px;
  color: #00b2af;
}
