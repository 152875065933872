.chatGroup {
  display: grid;
  grid-template-columns: 38% 38% 19%;
  gap: 6px;
  justify-content: center;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  margin-left: 10px;
}
