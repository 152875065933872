@media only screen and (min-width: 600px) {
  .columnsDataTable {
    display: flex;
    margin-right: 26px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .columnDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 17%;
    max-width: 17%;
    font-weight: bold;
    margin-right: 2%;
  }
  .columnStatusDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 10% !important;
    max-width: 10% !important;
    font-weight: bold;
    margin-right: 2%;
  }

  .iconFilterColumnDataTable {
    display: flex;
    align-self: center;
    cursor: pointer;
  }

  .dataTable {
    max-height: 95%;
    overflow-x: hidden;
    overflow-y: auto;

    .rowDataTable {
      display: flex;
      background-color: white;
      align-items: center;
      padding: 25px 0px 25px 0px;
      margin-top: 10px;
      box-shadow: 0px 2px 4px #0000001a;
      border-radius: 5px;
      padding-right: 28px;
      padding-left: 28px;
      // margin-right: 10px;
    }

    .itemDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 17%;
      max-width: 17%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .itemStatusDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 10% !important;
      max-width: 10% !important;
    }

    .Mui-checked {
      color: #6f2c91 !important;
    }

    .MuiSwitch-track {
      background-color: #d9bbe9 !important;
      opacity: 1 !important;
    }

    .actionIconDataTable {
      color: #9fa9bc;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .dataTable::-webkit-scrollbar {
    width: 7px;
    margin-left: -10px;
  }

  .dataTable::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  .dataTable::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  .msgEmptyDataTable {
    font-size: 23px;
    margin-top: 20px;
    color: #c79bde;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .columnsDataTable {
    display: flex;
    margin-right: 26px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .columnDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 17%;
    max-width: 17%;
    font-weight: bold;
    margin-right: 2%;
  }
  .columnStatusDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 10% !important;
    max-width: 10% !important;
    font-weight: bold;
    margin-right: 2%;
  }

  .iconFilterColumnDataTable {
    display: flex;
    align-self: center;
    cursor: pointer;
  }

  .dataTable {
    max-height: 95%;
    overflow-x: hidden;
    overflow-y: auto;

    .rowDataTable {
      display: flex;
      background-color: white;
      align-items: center;
      padding: 25px 0px 25px 0px;
      margin-top: 10px;
      box-shadow: 0px 2px 4px #0000001a;
      border-radius: 5px;
      padding-right: 28px;
      padding-left: 28px;
      // margin-right: 10px;
    }

    .itemDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 17%;
      max-width: 17%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .itemStatusDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 10% !important;
      max-width: 10% !important;
    }

    .Mui-checked {
      color: #6f2c91 !important;
    }

    .MuiSwitch-track {
      background-color: #d9bbe9 !important;
      opacity: 1 !important;
    }

    .actionIconDataTable {
      color: #9fa9bc;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .dataTable::-webkit-scrollbar {
    width: 7px;
    margin-left: -10px;
  }

  .dataTable::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  .dataTable::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  .msgEmptyDataTable {
    font-size: 23px;
    margin-top: 20px;
    color: #c79bde;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .columnsDataTable {
    display: flex;
    margin-right: 26px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .columnDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 17%;
    max-width: 17%;
    font-weight: bold;
    margin-right: 2%;
  }
  .columnStatusDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 10% !important;
    max-width: 10% !important;
    font-weight: bold;
    margin-right: 2%;
  }

  .iconFilterColumnDataTable {
    display: flex;
    align-self: center;
    cursor: pointer;
  }

  .dataTable {
    max-height: 95%;
    overflow-x: hidden;
    overflow-y: auto;

    .rowDataTable {
      display: flex;
      background-color: white;
      align-items: center;
      padding: 25px 0px 25px 0px;
      margin-top: 10px;
      box-shadow: 0px 2px 4px #0000001a;
      border-radius: 5px;
      padding-right: 28px;
      padding-left: 28px;
      // margin-right: 10px;
    }

    .itemDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 17%;
      max-width: 17%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .itemStatusDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 10% !important;
      max-width: 10% !important;
    }

    .Mui-checked {
      color: #6f2c91 !important;
    }

    .MuiSwitch-track {
      background-color: #d9bbe9 !important;
      opacity: 1 !important;
    }

    .actionIconDataTable {
      color: #9fa9bc;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .dataTable::-webkit-scrollbar {
    width: 7px;
    margin-left: -10px;
  }

  .dataTable::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  .dataTable::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  .msgEmptyDataTable {
    font-size: 23px;
    margin-top: 20px;
    color: #c79bde;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .columnsDataTable {
    display: flex;
    margin-right: 26px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .columnDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 17%;
    max-width: 17%;
    font-weight: bold;
    margin-right: 2%;
  }
  .columnStatusDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 10% !important;
    max-width: 10% !important;
    font-weight: bold;
    margin-right: 2%;
  }

  .iconFilterColumnDataTable {
    display: flex;
    align-self: center;
    cursor: pointer;
  }

  .dataTable {
    max-height: 95%;
    overflow-x: hidden;
    overflow-y: auto;

    .rowDataTable {
      display: flex;
      background-color: white;
      align-items: center;
      padding: 25px 0px 25px 0px;
      margin-top: 10px;
      box-shadow: 0px 2px 4px #0000001a;
      border-radius: 5px;
      padding-right: 28px;
      padding-left: 28px;
      // margin-right: 10px;
    }

    .itemDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 17%;
      max-width: 17%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .itemStatusDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 10% !important;
      max-width: 10% !important;
    }

    .Mui-checked {
      color: #6f2c91 !important;
    }

    .MuiSwitch-track {
      background-color: #d9bbe9 !important;
      opacity: 1 !important;
    }

    .actionIconDataTable {
      color: #9fa9bc;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .dataTable::-webkit-scrollbar {
    width: 7px;
    margin-left: -10px;
  }

  .dataTable::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  .dataTable::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  .msgEmptyDataTable {
    font-size: 23px;
    margin-top: 20px;
    color: #c79bde;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1900px) {
  .columnsDataTable {
    display: flex;
    margin-right: 26px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .columnDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 17%;
    max-width: 17%;
    font-weight: bold;
    margin-right: 2%;
  }
  .columnStatusDataTable {
    color: #101c36;
    font-size: 16px;
    display: flex;
    align-self: center;
    min-width: 10% !important;
    max-width: 10% !important;
    font-weight: bold;
    margin-right: 2%;
  }

  .iconFilterColumnDataTable {
    display: flex;
    align-self: center;
    cursor: pointer;
  }

  .dataTable {
    max-height: 95%;
    overflow-x: hidden;
    overflow-y: auto;

    .rowDataTable {
      display: flex;
      background-color: white;
      align-items: center;
      padding: 25px 0px 25px 0px;
      margin-top: 10px;
      box-shadow: 0px 2px 4px #0000001a;
      border-radius: 5px;
      padding-right: 28px;
      padding-left: 28px;
      // margin-right: 10px;
    }

    .itemDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 17%;
      max-width: 17%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .itemStatusDataTable {
      font-size: 16px;
      color: #9fa9bc;
      margin-right: 2%;
      min-width: 10% !important;
      max-width: 10% !important;
    }

    .Mui-checked {
      color: #6f2c91 !important;
    }

    .MuiSwitch-track {
      background-color: #d9bbe9 !important;
      opacity: 1 !important;
    }

    .actionIconDataTable {
      color: #9fa9bc;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .dataTable::-webkit-scrollbar {
    width: 7px;
    margin-left: -10px;
  }

  .dataTable::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  .dataTable::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  .msgEmptyDataTable {
    font-size: 23px;
    margin-top: 20px;
    color: #c79bde;
    padding-left: 20px;
  }
}
