.externalModalContainer {
  max-width: 600px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  background-color: white;
  border-radius: 4px;
  margin-top: 2rem;
  padding: 1rem;
}

.externalFull {
  height: 732px;
}

.externalPartial {
  height: 550px;
}

.modalLastUpdate {
  font-size: 0.625rem;
  color: #917a7a;
  margin-bottom: 0.5rem;
}

.modalMaster {
  font-size: 1.2rem;
  font-weight: 700;
  color: #917a7a;
}

.activityLocation {
  font-size: 0.625rem;
}

.activityDate {
  font-size: 0.825rem;
}

.circleActivity {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 4px solid #6f2c91;
  background-color: #6f2c91;

  text-align: center;
  margin-right: 0.75rem;
  margin-left: 0.5rem;
  z-index: 9999;
  margin-top: -8px;
}
.gridActivity {
  font-weight: 600;
  color: #6b6b6b;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.textActivity {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gridActivityLeft {
  font-weight: 600;
  color: #6b6b6b;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
}

.circleActivityLeft {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 4px solid #6f2c91;
  background-color: #6f2c91;
  text-align: left;
  z-index: 9999;
  margin-top: -8px;
}

.gridActivityRight {
  font-weight: 600;
  color: #6b6b6b;
  display: flex;
  flex-direction: column;
  text-align: right;
  // justify-content: flex-end;
  // align-items: right;
}

.circleActivityRight {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 4px solid #6f2c91;
  background-color: #6f2c91;
  text-align: center;
  z-index: 9999;
  margin-top: -8px;
}

.tabAtividade {
  bottom: 0;
  font-size: 14px;
  overflow-y: scroll;
  max-height: 400px;
}

.tabAtividade::-webkit-scrollbar {
  display: none;
}
.select {
  padding: 8px;
  justify-content: center;
  align-content: center;
}
