.title-grid {
  font-size: 14px;
  font-weight: bold;
}

.subtitle-grid {
  font-size: 12px;
  margin-bottom: 3px;
}

.bgGray {
  background-color: #dedede;
}

.span-text {
  display: block;
  overflow: hidden;
  max-width: 18ch !important;
  white-space: nowrap;
}
