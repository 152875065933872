.document-item {
  span {
    color: gray;
    font-weight: 700;
  }

  p {
    font-size: 12px;
  }
}
