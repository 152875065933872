.note-item {
  width: 100%;

  .note-priority {
    /* margin-bottom: 2px; */
    font-size: 11px !important;
    font-weight: bold !important;
    text-align: right;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff !important;
    background-color: gray;
    padding: 4px 8px 2px 8px;
    border-radius: 8px;
    width: fit-content;
  }

  .note-info {
    font-size: 12px !important;
    font-weight: bold !important;
    text-align: right;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #5b5b5b !important;
    opacity: 0.8;
    margin-bottom: 0;

    span {
      font-size: 10px !important;
    }
  }

  .note-content {
    text-align: justify;
    font-size: 14px !important;
    color: #5b5b5b !important;
    opacity: 1;
    /* margin: 0 0 0 24px; */
    margin-bottom: 2px;
  }

  .note-divider {
    text-align: center;
    width: 70%;
  }

  .note-add {
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
      0 0 0 1px rgba(9, 30, 66, 0.08);
    margin-bottom: 16px;
    position: relative;
  }

  .note-button {
    /*position: absolute;
			bottom: 2px;
			left: 2px;*/
    background-color: #f2dffc;
    color: #6f2c91;
    display: block;
  }

  .note-priority-select {
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 32px;
  }

  .dot {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 6px;
    margin-top: 8px;
    background-color: #bbb;
    opacity: 0.6;
    cursor: pointer;
  }

  .high {
    background-color: red;
  }

  .normal {
    background-color: orange;
  }

  .low {
    background-color: green;
  }

  .note-actions {
    display: grid;
    grid-template-columns: 85% 15%;
  }

  .note-icons {
    color: #5b5b5b !important;
    opacity: 0.9;
    cursor: pointer;
    margin-left: 8px;
  }
}
