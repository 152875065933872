.terms-container {
  max-width: 80%;
  margin: 0 auto;
  height: 60px;
  margin-bottom: 20px;
  color: #fff;
  background: #000;
  opacity: 0.9;

  font-size: 0.725rem;

  display: flex;
  flex-direction: row;

  strong {
    cursor: pointer;
    color: #6f2c91;
  }

  button {
    background: #6f2c91;
    color: #fff;
  }

  button:hover {
    background: #fff;
    color: #6f2c91;
  }
}

.terms-card {
  max-height: 600px;
  overflow-y: scroll;
  border: none;

}