.request-revision {
  max-width: 640px;
  top: calc(35% - 150px) !important;
  left: calc(50% - 320px) !important;

  .buttons-area {
    display: flex;
    justify-content: space-between;
    margin: 1.5%;

    .primary {
      color: #fff;
      background-color: #6f2c91;
      border-color: #54206e;

      .primary:hover {
        color: #333;
      }
    }
  }
}
