.modal-subtitle {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: -35px;
  margin-left: 76px;
  margin-bottom: 22px;

  .subTitleCardCard {
    font-size: 12px !important;
    text-align: left;
    width: 100%;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* margin-bottom: 15px !important; */
  }
}

.title-grid {
  font-size: 14px;
  font-weight: bold;
}

.subtitle-grid {
  font-size: 12px;
}

.MuiDialog-paperFullWidth {
  height: 85%;
}

.MuiDialogContent-root:first-child {
  overflow-x: hidden;
}

.MuiDialog-paperWidthSm {
  max-width: 700px !important;
}

.chat .MuiDialog-paperWidthSm {
  max-width: calc(100% - 64px) !important;
}

@media only screen and (min-width: 1300px) {
  .chat .MuiDialog-paperWidthSm {
    max-width: 90% !important;
  }
}

@media only screen and (min-width: 1500px) {
  .chat .MuiDialog-paperWidthSm {
    max-width: 80% !important;
  }
}

@media only screen and (min-width: 1500px) {
  .chat .MuiDialog-paperWidthSm {
    max-width: 70% !important;
  }
}

@media only screen and (min-width: 1900px) {
  .chat .MuiDialog-paperWidthSm {
    max-width: 60% !important;
  }
}

.dialogContent {
  padding: "0px";
  padding-top: 0px !important;
  left: 0;
  min-height: 500px;
  overflow-y: scroll;
}

.dialogContent::-webkit-scrollbar {
  display: none;
}

.infoBorderCard {
  border: 1px grey;
  border-radius: 5px;
  font-size: 12px !important;
  /* width: fit-content; */
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding: 5px;
  font-weight: bold !important;
}

.new-pendent {
  background: rgba(252, 67, 40, 0.1);
  color: #fc4328;

  text-align: center;
  padding: 0.5rem;
  border-radius: 4px;

  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    font-size: 12px !important;
    // max-width: 25ch !important;
    // text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }
}

.new-payment {
  background: rgb(229, 247, 247);
  color: rgb(0, 178, 175);
  text-align: center;
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    font-size: 12px !important;
    // max-width: 25ch !important;
    // text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }
}
