.financial-modal-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;

  max-width: 585px;
  margin: 0 auto;
  margin-top: 2px;

  padding: 8px;
  background-color: #fff;
  border-radius: 4px;
}

.financial-file {
  padding: 1px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center !important;
  margin: 0 auto;
  margin-bottom: 8px;
  flex-direction: column;
  position: relative;
}

.container-buttons {
  display: flex;
  justify-content: center;

  .primary {
    color: #fff;
    background-color: #6f2c91;
    border-color: #54206e;

    .primary:hover {
      color: #333;
    }
  }
}

.modalListUser {
  height: auto !important;
}

.MuiSwitch-switchBase {
  &.Mui-checked {
    color: #6f2c91 !important;
  }
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #54206e !important;
}
