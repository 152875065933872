.timelineExternal {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  margin-left: 20px;
}

.timelineExternal::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: grey;
  top: 20px;
  bottom: 0;
  margin-left: -9px;
}

.containerExternal {
  position: relative;
  background-color: inherit;
}

.containerExternal::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -17px;
  background-color: white;
  border: 4px solid #6f2c91;
  top: 15px;
  // margin-left: 16px;
  margin-top: 4px;
  border-radius: 50%;
  z-index: 1;
}

.rightExternal {
  left: 0%;
  padding-top: 3%;
}

.rightExternal::after {
  left: -16px;
}

.content {
  padding: 0px 0px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  height: 114px;
  overflow: auto;
  padding-left: 4% !important;
}

.content::-webkit-scrollbar {
  width: 7px;
  margin-left: -10px;
}

.content::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

.content::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

.timelineExternal::after {
  left: 0px;
}

.newTimeLineText {
  font-size: 0.625rem !important;
}
