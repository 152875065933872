.myswitch {
  .MuiFormGroup-root {
    height: auto !important;
  }
}

.popover-notify-list {
  // flex-grow: 3;

  display: flex;
  flex-direction: column;
  min-width: 500px !important;

  .arrow {
    display: none;
  }
  .popover-header::before {
    display: none;
  }

  .popover-notify-title {
    background: #573c66;
    color: #fff;
    height: 40px;
    display: flex;

    align-items: center;

    .text {
      font-size: 0.875rem;
      text-transform: uppercase;
    }
    .MuiTypography-body1 {
      font-size: 0.825rem !important;
    }
  }

  &-content {
    max-height: 610px;
    overflow-y: scroll;
  }
}

.popover-notify-footer {
  display: flex;
  justify-content: space-between;
  background: #573c66;
  height: 40px;

  .MuiButton-contained {
    box-shadow: none;
  }

  button {
    background: none;
    color: #fff;
  }

  button:hover {
    background-color: transparent;
    box-shadow: none;
    color: #c79bde;
  }
}

.popover-notify-list-content::-webkit-scrollbar {
  width: 7px;
}

.popover-notify-list-content::-webkit-scrollbar-thumb {
  background: #6f2c91;
  border-radius: 20px;
}

.popover-notify-list-content::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

.popover-body {
  padding: 0;
}

.body-message {
  p {
    margin-bottom: 0px;
    color: #fc4328;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.body-message-nm {
  p {
    margin-bottom: 0px;
    color: #010101;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.date-message {
  font-size: 10px;
  color: #767676;
}
