.my-toast {
  background: #c79bde;
  padding: 0;
  height: 48px;

  &-body {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
