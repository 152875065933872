// img {
//   max-width: 64px;
// }

.dateBorderCard {
  padding: 2px 6px;
  font-size: 12px !important;
}

.statusCargo {
  font-size: 12px;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  background: #e5f7f7;
  color: #00b2af;
  align-self: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding-left: 3px;
  margin-bottom: 0.3rem !important;
}

.gm-ui-hover-effect {
  top: 6px !important;
  right: 6px !important;
}

.icon-title {
  font-size: 14px;
  margin-right: 6px;
}

.icon-micro-status {
  font-size: 10px;
  margin-right: 4px;
}

.App {
  text-align: unset;
}

.mapboxgl-canvas {
  border-radius: 5px;
  width: 100% !important;
  position: absolute;
}

.map {
  margin-top: 5px;
  width: 100%;
  height: 83%;
}

.map-container {
  z-index: 555;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  float: left;
}

.mapboxgl-canvas-container
  mapboxgl-interactive
  mapboxgl-touch-drag-pan
  mapboxgl-touch-zoom-rotate {
  position: absolute;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.mapbox-custom-popup {
  min-width: 280px;

  button {
    background: none;
    border: none;
    color: gray;
    position: absolute;
    right: 0;
    padding: 5%;
  }

  .popup-ship-info {
    color: #6b6b6b;
    display: block !important;
    /*
		background-color: white;
		opacity: 1;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 5%;
		border-radius: 10px;
		*/
    hr {
      margin-top: 7px;
      margin-bottom: 7px;
    }

    /* .popupToClusterContent {
			width: 100%;*/

    .titlePopup {
      p {
        font-size: 15px;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0px;
      }
    }

    .contentCargoContentPopup {
      max-height: 350px;
      overflow: auto;
    }

    .originDestinationPopup {
      font-size: 10px;

      p {
        margin-bottom: 0px;
      }
    }

    .dateBorderPopup {
      border-radius: 5px;
      width: fit-content;
      text-align: center;
      background: #ffe3df;
      color: #fc4328;
      font-size: 10px;

      p {
        margin-bottom: 0px;
      }
    }

    .subTitlePopup {
      p {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0px;
      }
    }

    .listCargoContentPopup {
      /*display: flex;
				width: 100%;*/
      justify-content: space-between;
      cursor: pointer;
      padding-bottom: 3px;

      .codeCargo {
        font-size: 12px;
        /* width: 40%; */
        align-self: center;
        margin-right: 5px;
        align-self: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden !important;
        flex: 1;

        p {
          margin-bottom: 0px;
        }
      }

      .statusCargo {
        font-size: 12px;
        width: 60%;
        border-radius: 5px;
        text-align: center;
        background: #e5f7f7;
        color: #00b2af;
        align-self: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden !important;
        padding-left: 3px;

        p {
          margin-bottom: 0px;
        }
      }
    }

    .contentCargoContentPopup::-webkit-scrollbar {
      width: 7px;
      margin-left: -10px;
    }

    .contentCargoContentPopup::-webkit-scrollbar-thumb {
      background: #666;
      border-radius: 20px;
    }

    .contentCargoContentPopup::-webkit-scrollbar-track {
      background: #ddd;
      border-radius: 20px;
    }
    //}

    .popupToUncluster {
      width: 100%;

      .titlePort {
        p {
          font-size: 20px;
          font-weight: bold;
        }
      }

      .subTitlePopup {
        p {
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      .contentCargoContentPopup {
        max-height: 240px;
        overflow: auto;

        .titlePopup {
          p {
            color: #fc4328;
            font-size: 15px;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 0px;
          }
        }

        .listCargoContentPopup {
          /*display: flex;
					width: 100%;*/
          justify-content: space-between;
          cursor: pointer;

          .codeCargo {
            flex: 1;
            font-size: 12px;
            align-self: center;
            margin-right: 5px;

            p {
              margin-bottom: 0px;
            }
          }

          .statusCargo {
            flex: 1;
            font-size: 12px;
            align-self: center;
            color: #00b2af;
            width: fit-content;
            border: 1px grey;
            overflow: hidden !important;
            font-size: 12px;
            max-width: 50%;
            background: #e5f7f7;
            white-space: nowrap;
            border-radius: 5px;
            display: inline-block;
            text-overflow: ellipsis;

            p {
              margin-bottom: 0px;
            }
          }
        }
      }

      .contentCargoContentPopup::-webkit-scrollbar {
        width: 7px;
        margin-left: -10px;
      }

      .contentCargoContentPopup::-webkit-scrollbar-thumb {
        background: #666;
        border-radius: 20px;
      }

      .contentCargoContentPopup::-webkit-scrollbar-track {
        background: #ddd;
        border-radius: 20px;
      }
    }

    .popupToPointContent {
      width: 100%;

      .containerItems {
        max-height: 200px;
        overflow: auto;
      }

      .titlePopup {
        p {
          color: #fc4328;
          font-size: 15px;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 0px;
        }
      }

      .originDestinationPopup {
        font-size: 10px;

        p {
          margin-bottom: 0px;
        }
      }

      .dateBorderPopup {
        border-radius: 5px;
        width: fit-content;
        text-align: center;
        background: #ffe3df;
        color: #fc4328;
        font-size: 10px;

        p {
          margin-bottom: 0px;
        }
      }

      .subTitlePopup {
        p {
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 0px;
        }
      }

      .listCargoContentPopup {
        /*display: flex;
				width: 100%;*/
        justify-content: space-between;
        cursor: pointer;

        .codeCargo {
          font-size: 12px;
          align-self: center;
          margin-right: 5px;
          flex: 1;

          p {
            margin-bottom: 0px;
          }
        }

        .statusCargo {
          font-size: 12px;
          align-self: center;
          color: #00b2af;
          width: fit-content;
          border: 1px grey;
          overflow: hidden !important;
          font-size: 12px;
          max-width: 50%;
          background: #e5f7f7;
          white-space: nowrap;
          border-radius: 5px;
          display: inline-block;
          text-overflow: ellipsis;

          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.lastUpdate {
  width: 100%;
  text-align: right;
  font-size: 10px !important;
}
