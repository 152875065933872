.title-timeline {
  margin-bottom: 0;
  margin-top: 0px !important;
  color: #00b2af;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  .Mui-disabled {
    border-color: #00b2af !important;
    color: #00b2af !important;
  }

  .titleResolvePendingTimeline {
    font-size: 16px;
    color: #707070;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .btnResolvePendingTimeline {
    background-color: #6f2c91;
    color: white;
    font-weight: bold;
    border-color: #6f2c91;
  }
}

.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: grey;
  top: 20px;
  bottom: 0;
  margin-left: -9px;
}

.container {
  position: relative;
  background-color: inherit;
}

.container::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -17px;
  background-color: white;
  border: 4px solid #6f2c91;
  top: 15px;
  // margin-left: 16px;
  margin-top: 4px;
  border-radius: 50%;
  z-index: 1;
}
.content {
  padding: 0px 0px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  height: 114px;
  overflow: auto;
  padding-left: 4% !important;
}

.content::-webkit-scrollbar {
  width: 7px;
  margin-left: -10px;
}

.content::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

.content::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

.timeline::after {
  left: 0px;
}
