.modalErro {
  position: absolute;
  width: 350px;
  background-color: white;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .msgErro {
    color: #6f2c91;
    font-size: 30px;
    font-weight: bold;
    align-self: center;
    text-align: center;
    padding-top: 36px;
  }
}

.btnCloseModalErro {
  float: right;
  background-color: #6f2c91;
  margin-top: 10px;
  margin-right: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  button {
    color: white !important;
  }
}

.Mui-disabled input {
  background: transparent;
}
