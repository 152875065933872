.alert-container {
  position: relative;
  width: 300px;
  background: #fff;
  color: #6f2c91;
  padding: 0;
  margin: 0 auto;
  margin-top: 5rem;
  font-size: 1rem;
  border-radius: 8px;
  height: 250px;

  .alert-header {
    font-size: 1.4rem;
    padding: 0.5rem;
    font-weight: 700;
  }

  .alert-body {
    font-size: 1.6rem;
    color: #6f2c91;
    font-weight: 400;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
  .alert-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    &-cancel {
      background: #fff;
      border: 1px solid #6f2c91;
      color: #6f2c91;
      height: 50px;
      width: 80px;
      font-weight: bold;
      text-transform: none;
    }

    &-confirm {
      text-align: left;
      background-color: #6f2c91 !important;
      color: white !important;
      width: 150px;
      height: 50px;
      font-weight: bold;
      text-transform: none;
      margin-bottom: 8px;
    }
  }
}
