* {
  box-sizing: border-box;
}

body {
  background-color: #474e5d;
  // font-family: Helvetica, sans-serif;
}

h5 {
  margin: 0 !important;
}

h4 {
  margin-bottom: 0;
}

.text-justify {
  text-align: justify;
}

.mg-0 {
  margin: 0px;
}

.fs-15 {
  font-size: 15px;
}

.fs-18 {
  font-size: 18px;
}

.text-red {
  color: red;
}

.text-grey {
  color: grey;
}

.title-timeline-issue {
  padding-left: 6%;
  margin-bottom: 0;
  margin-top: 10px !important;
  color: #00b2af;
}

.timeline-issue {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  .Mui-disabled {
    border-color: #00b2af !important;
    color: #00b2af !important;
  }

  .titleResolvePendingTimeline {
    font-size: 16px;
    color: #707070;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .btnResolvePendingTimeline {
    background-color: #6f2c91;
    color: white;
    font-weight: bold;
    border-color: #6f2c91;
  }
}

.timeline-issue::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: gray;
  top: 20px;
  bottom: 0;
  // margin-left: -9px;
}

.issue-container {
  position: relative;
  background-color: inherit;
}

.issue-container::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -17px;
  background-color: white;
  border: 4px solid red;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.content {
  padding: 0px 0px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  height: 100%;
  overflow: auto;
  // padding-left: 4% !important;
}

.content::-webkit-scrollbar {
  width: 7px;
  margin-left: -10px;
}

.content::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

.content::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

.timeline-issue::after {
  left: 0px;
}

.issue-container {
  width: 100%;
  padding-left: 12px;
  // padding-right: 25px;
}

.issue-container::before {
  left: 60px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

h5 {
  margin: 0 !important;
}

h4 {
  margin-bottom: 0;
}
