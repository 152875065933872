.user-item {
  display: flex;
  background-color: white;
  align-items: center;
  padding: 20px;
  margin-top: 10px;
  box-shadow: 0px 2px 4px #0000001a;
  border-radius: 5px;
  margin-right: 10px;

  display: grid;
  grid-template-columns: 10% 35% 30% 20% 10%;

  .text {
    font-size: 16px !important;
    color: #9fa9bc;
    margin-right: 2%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
