.otherModalComponentContainer {
  position: absolute;
  // max-width: 400px;
  // max-height: 600px;
  background-color: white;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;

  .MuiDivider-root {
    height: 1px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .titlePageOtherModal {
    color: #6f2c91;
    font-size: 1.875rem;
    font-weight: bold;
    text-align: center;
  }

  .containerFieldOtherModal {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    //height: 370px;
    overflow: auto;

    .containerObsOtherModal {
      width: 100%;
    }
  }
  .containerFieldOtherModal::-webkit-scrollbar {
    display: none;
    width: 7px;
    margin-left: -10px;
  }

  .containerFieldOtherModal::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  .containerFieldOtherModal::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  .titleFieldOtherModal {
    margin-top: 0.1rem;
    margin-bottom: 0.2rem;
    color: #9597a6;

    span {
      color: red;
    }
  }

  .itemOtherModal {
    width: 100%;
    padding: 8px;
    div {
      width: 100%;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #c79bde;
    }

    input {
      color: #ada5b1;
    }

    .Mui-checked {
      color: #6f2c91 !important;
    }
  }
  .itemOtherModal2 {
    width: 100%;
    padding: 8px;
    padding-top: 24px;
    div {
      width: 100%;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #c79bde;
    }

    input {
      color: #ada5b1;
    }

    .Mui-checked {
      color: #6f2c91 !important;
    }
  }

  .obsOtherModal {
    fieldset {
      border-color: #c79bde;
    }
  }

  .buttonsOtherModal {
    padding-top: 0.5rem;
    display: block;
    justify-content: end;
    text-align: end;
    width: 100%;
    align-items: end;

    .btnSaveOtherModal {
      background-color: #6f2c91;
      color: white;
      text-transform: uppercase;
      width: 50%;
      padding: 1rem;
      margin-bottom: 2rem;
    }
  }

  .return-text {
    font-size: 0.625rem;
    margin-top: 0.725rem;
    text-transform: uppercase;
    color: #5b5b5b;
    font-weight: 700;
  }

  .return-info {
    font-size: 0.625rem;
    margin-top: 0.725rem;

    .col:last-child {
      text-transform: uppercase;
    }
  }

  .return-container {
    overflow: hidden;
  }
}
