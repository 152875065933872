:root {
  --color-background: #24122d;
  --color-primary: #6f2c91;
  --color-primary-dark: #c1c1c1;
}

.darkTheme {
  background: #121212;
  color: var(--color-primary-dark);
}

.lightTheme {
  background: #ffffff;
  color: var(--color-primary);
}

h5 {
  margin: 0 !important;
}

h4 {
  margin-bottom: 0;
}

.text-justify {
  text-align: justify;
}

.mg-0 {
  margin: 0px;
}

.fs-15 {
  font-size: 15px;
}

.fs-18 {
  font-size: 18px;
}

.text-red {
  color: red;
}

.text-grey {
  color: grey;
}
