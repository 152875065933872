.newOrderComponentContainer {
  position: absolute;
  width: 800px;
  background-color: white;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  padding: 32px;

  .MuiDivider-root {
    height: 1px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .titlePageNewOrder {
    color: #6f2c91;
    font-size: 30px;
    font-weight: bold;
  }

  .containerFieldNewOrder {
    display: flex;
    margin-bottom: 10px;
    // justify-content: space-between;

    .containerObsNewOrder {
      width: 50%;
    }
  }

  .titleFieldNewOrder {
    margin-bottom: 5px;
    color: #9597a6;

    span {
      color: red;
    }
  }

  .itemNewOrder {
    width: 32%;

    div {
      width: 100%;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #c79bde;
    }

    input {
      color: #ada5b1;
    }

    .Mui-checked {
      color: #6f2c91 !important;
    }
  }

  .obsNewOrder {
    fieldset {
      border-color: #c79bde;
    }
  }

  .buttonsNewOrder {
    float: right;

    .btnCloseNewOrder {
      border-color: #6f2c91;
      color: #6f2c91;
      margin-right: 30px;
      text-transform: capitalize;
    }

    .btnSaveNewOrder {
      background-color: #6f2c91;
      color: white;
      text-transform: capitalize;
    }
  }

  .containerFileNewOrder {
    margin-bottom: 25px;

    .contentFileNewOrder {
      border: 1px solid #a4a0a0;
      opacity: 1;
      border-radius: 10px;

      display: flex;
      flex-direction: row;
    }

    .containerUploadNewOrder {
      width: 40%;
      border-right: 1px solid #a4a0a0;
      padding: 10px 20px 10px 20px;

      .descriptionUploadNewOrder {
        border: 1px dashed;
        padding: 10px;
        border-radius: 5px;
        margin-top: 15px;
        background-color: #f3f3f3;

        p {
          text-align: center;
          margin-bottom: 5px;
        }

        div {
          text-align: center;
        }

        img {
          color: #6f2c91;
          font-size: 38px;
          margin-bottom: 15px;
        }
      }

      .btnUploadNewOrder {
        background-color: #6f2c91;
        color: white;
        border-radius: 9px;
      }
    }

    .containerListNewOrder {
      padding: 10px 20px 10px 20px;

      .containerItemsNewOrder {
        overflow-y: scroll;
        max-height: 200px;
        width: 300px;
      }

      .containerItemsNewOrder::-webkit-scrollbar {
        display: none;
      }

      .itemListUploadNewOrder {
        display: flex;
        margin-bottom: 20px;
        margin-top: 20px;
        height: 45px;

        .previewItemNewOrder {
          align-self: center;

          img {
            width: 70px;
            max-height: 45px;
          }
        }

        .headerItemNewOrder {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          color: #9597a6;
          color: #9597a6;

          svg {
            color: #9fa9bc;
            cursor: pointer;
          }

          .itemNameNewOrder {
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 35ch !important;
            overflow: hidden !important;
          }
        }

        .containerLoadingItemNewOrder {
          padding-left: 15px;
          padding-right: 15px;

          .loadingItemNewOrder {
            height: 4px;
            background-color: #00b2af;
          }

          p {
            color: #9597a6;
            margin: 0;
          }
        }
      }
    }

    .titleFileNewOrder {
      font-size: 20px;
      color: #9597a6;
    }
  }

  .inputFileNewOrder {
    cursor: pointer;
    background-color: #ececec;
    height: 56px;
    border-radius: 5px;
    text-align: center;
    display: flex;

    div {
      align-self: center;
    }
  }
}
