.date-filter {
  width: 320px;
  // padding: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.coleta-section {
  display: grid !important;
  grid-template-columns: 50% 50%;
}

.date-section {
  display: grid !important;
  grid-template-columns: 48% 48%;
  gap: 4%;
}

.date-buttons {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
}

.buttons-section {
  display: flex !important;
  justify-content: space-between;
  margin-top: 8px;
}

label {
  margin-bottom: 0 !important;
}

hr {
  width: 100%;
  margin-bottom: 8px;
  margin-top: 0;
}

.button-dates {
  margin-bottom: 6px;
}

// .date-filter {
//   width: 420px;
//   padding: 12px;
//   display: flex;
//   flex-direction: column;
//   flex: 1;
// }

// .coleta-section {
//   display: grid !important;
//   grid-template-columns: 50% 50%;
// }

// .date-section {
//   display: grid !important;
//   grid-template-columns: 50% 50%;
// }

// .date-buttons {
//   display: grid !important;
//   grid-template-columns: 1fr 1fr 1fr;
// }

// .buttons-section {
//   display: flex !important;
//   justify-content: space-between;
//   margin-top: 8px;
// }

// // span {
// //   font-size: 12px !important;
// // }

// label {
//   margin-bottom: 0 !important;
// }

// hr {
//   width: 100%;
//   margin-bottom: 0;
//   margin-top: 0.5rem;
// }

// .button-dates {
//   margin-bottom: 6px;
// }
