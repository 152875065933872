.private-root {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  // overflow-x: scroll;
  // box-sizing: border-box;

  .button-map-actived {
    z-index: 46 !important;
    position: absolute !important;

    transform: translate(50%, -50%);
    top: 23%;
    right: 0px;
    // width: 52px;
    // z-index: 46 !important;
    // position: absolute;
    // right: 20px;
    // top: 220px;
    // transform: translateX(-120%);
    animation: 0.5s move 1s forwards ease-in;

    button {
      border: none;
      color: #00b2af;
      border-top-left-radius: 45px;
      border-bottom-left-radius: 45px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      background: #24122d;
      height: 150px;
      width: 52px;
      font-size: 11px !important;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .map-none {
    display: none;
    animation-direction: normal;
  }

  .map-home {
    z-index: 45 !important;
    width: 100%;
    height: 100vh !important;
    position: absolute;
    transform: translateX(120%);
    animation: 1.5s move 1s forwards ease-in;
    overflow: hidden;
  }

  @keyframes move {
    to {
      transform: translateX(0);
    }
  }
}

.private-root::-webkit-scrollbar {
  display: none;
}

.private-content {
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 60px);
}

.private-content::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.loading {
  color: #c79bde !important;
  z-index: 9999999999 !important;
}
